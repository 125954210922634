<script>
import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

import { getListingApi } from '@/api/listing'
import { searchAgent } from '@/api/misc'


/**
 * User list component
 */
export default {
  page: {
    title: "Pending List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      listingData: [],
      title: "Pending List",
      items: [
        {
          text: "Listing",
          href: "/",
        },
        {
          text: "Pending List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "ListingId",
          sortable: true,
        },
        {
          key: "Agent",
          sortable: false,
        },
        {
          key: "Address",
          sortable: true,
        },
      
      
        {
          key: "StartDate",
          sortable: true,
        },
        {
          key: "ExpiryDate",
          sortable: true,
        },
        {
          key: "Price",
          sortable: true,
        },
        {
          key: "Status",
          sortable: false,
        },
        {
          key: "Operator",
          sortable: false,
        },

        {
          key: "DocSigned",
          sortable: false,
        },

      ],
      coinvalue: "",
      statusValue: "",
      typeValue: null,
      datepickervalue: "",
      showModal: false,
      contact: {
        name: "",
        designation: "",
        email: "",
        file: "",
      },
      filter: {
        status: [],
        agent_id: '',
      },
      submitted: false,

      agent_choice: {},
      agents: []
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
    new Choices("#choices-filter-doc-signed-input", {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
      searchEnabled: false,

    }).setChoices([
      { label: "ALL", value: "" },
      { label: "Signed", value: "1" },
      { label: "UnSigned", value: "0" },
    ]);


    this.agent_choice = new Choices("#choices-filter-agent-input", {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
    })


    new Choices("#choices-filter-status-input", {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
      removeItemButton: true,
    }).setChoices([
      {label : "SUBMITTED", value : 'SUBMITTED'},
      {label : "PROCESSING", value : 'PROCESSING'},
      {label : "REJECTED", value : 'REJECTED'},
      {label : "ACTIVE", value : 'ACTIVE'},
      {label : "UPDATED", value : 'UPDATED'},
      {label : "CANCELLED", value : 'CANCELLED'},
      {label : "TERMINATED", value : 'TERMINATED'},
      {label : "EXPIRED", value : 'EXPIRED'},
    ])



  },
  methods: {

    queryList() {

      this.filter.signed_type = this.filter.is_signed

      getListingApi().list({ page: { page: this.currentPage, page_size: this.perPage }, filter: this.filter }).then((res) => {
        this.listingData = []
        res.data.map((o) => {

          this.listingData.push({
            id: o.id,
            listing_id: o.agent_listing_id,
            address: o.address,
            price: o.listing_price,
            plist: o.plist,
            sellers: o.sellers,
            start_date: o.listing_effective_date,
            expiry_date: o.listing_expiry_date,
            display_status: o.display_status,
            agent: o.agent,
            isSelected: false,
            is_signed: o.is_signed,
          })
         
        })

        this.totalRows = res.page.total;

      })
    },



    onSearchedAgent(e) {
      searchAgent({ "str": e.detail.value }).then((res) => {
        this.agents = [
          { label: 'ALL', value: '', agent: { id: '' } }
        ]
        res.data.map((a) => {
          this.agents.push({
            label: `${a.first_name} ${a.last_name}`,
            value: a.id,
            agent: a
          })
        })
        this.agent_choice.clearChoices()
        this.agent_choice.setChoices(this.agents)
        this.agent_choice.showDropdown()
      })

    },

    onEnterSearch() {
      this.currentPage = 1
      this.queryList()
    },

    onSelectAgent(ev) {
      let f = this.agents.find((f) => f.value == ev.detail.value)
      this.filter.agent_id = f.agent.id
      this.queryList()
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered() {
      this.currentPage = 1;
      this.queryList()
    },


  },
  validations: {
    contact: {
      name: { required },
      designation: { required },
      email: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <div class="search-box  col-md-3">
                  <div class="position-relative">
                    <select class="form-control" v-model="filter.status" @change="onFiltered()"
                      name="choices-filter-status-input" id="choices-filter-status-input" multiple>
                    </select>
                  </div>
                </div>

                <div class="search-box col-md-2">
                  <div class="position-relative">
                    <input type="text" class="form-control bg-light border-light rounded" v-model="filter.search_str"
                      placeholder="Search Address/Listing Id..." @keyup.enter="onEnterSearch" />
                    <i class="uil uil-search search-icon"></i>
                  </div>
                </div>
                <div class="search-box  col-md-2">
                  <div class="position-relative">
                    <select class="form-control" v-model="filter.is_signed" @change="onFiltered()"
                      name="choices-filter-doc-signed-input" id="choices-filter-doc-signed-input">
                      <option value="">ALL</option>

                    </select>
                  </div>
                </div>

                <div class="search-box  col-md-2">
                  <div class="position-relative">
                    <select class="form-control" v-model="filter.agent_id" @search="onSearchedAgent"
                      @change="onSelectAgent" name="choices-filter-agent-input" id="choices-filter-agent-input">
                      <option value=""></option>

                    </select>
                  </div>
                </div>

              </div>


            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="listingData" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter-included-fields="filterOn" @filtered="onFiltered"
                  class="table-check">

                  <template #cell(ListingId)="data">
                    <router-link :to="{ name: 'listing-overview', query: { listing_id: `${data.item.listing_id}` } }"
                      class="text-body fw-medium">{{ data.item.listing_id }}</router-link>
                  </template>
                  <template #cell(Address)="data">
                    <span v-for="p, idx in data.item.plist" :key="idx">
                      {{ p.unit + ' ' + p.address + ' ' + p.city_name + ' ' + p.postal_code }}<br />
                    </span>
                  </template>

                  <template #cell(Agent)="data">
                    <router-link
                      :to="{ name: 'brokerage-agent-view', params: { agent: data.item.agent, agent_id: data.item.id + '' } }"
                      class="text-body fw-medium"> {{ data.item.agent.name }}</router-link>
                  </template>

                  <template #cell(Price)="data">
                    ${{ Number(data.item.price).toLocaleString() }}
                  </template>

                  <template #cell(StartDate)="data">
                    {{ data.item.start_date }}
                  </template>
                  <template #cell(ExpiryDate)="data">
                    {{ data.item.expiry_date }}
                  </template>
                  <template #cell(Status)="data">
                    {{ data.item.display_status }}
                  </template>
                  <template #cell(DisplayType)="data">
                    {{ data.item.display_type }}
                  </template>
                  <template #cell(DocSigned)="data">
                    {{ data.item.is_signed == 1 ? 'Yes' : 'No' }}
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">

              <div class="col-sm-3">
                <div class="ml-3">
                  <h5 class="card-title">
                    Total
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->
              <div class="col-sm-9">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
