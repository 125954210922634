
import request from '@/utils/request';



class ListingApi {
    

    list = (data) => {

        return request({
            url: '/adm/listing/pending_list',
            method: 'post',
            data
        });	


        // return new Promise((resolve, reject) => {

            
        //     firebase.auth().createUserWithEmailAndPassword(email, password).then((user) => {
        //         // eslint-disable-next-line no-redeclare
        //         var user = firebase.auth().currentUser;
        //         resolve(user);
        //     }, (error) => {
        //         reject(this._handleError(error));
        //     });
        // });
    }


    exclusive_list = (data) => {

        return request({
            url: '/adm/listing/exclusive_list',
            method: 'post',
            data
        });	
    }


    active_list = (data) => {

        return request({
            url: '/adm/listing/mls_list',
            method: 'post',
            data
        });	
    }

    detail = (data) => {

        return request({
            url: '/adm/listing/listing_detail',
            method: 'post',
            data
        });	
    }



    type_list = () => {
        return request({
            url: '/adm/config/listing/listing_type',
            method: 'post',
        });	
    }

    status_list = () => {
        return request({
            url: '/adm/config/listing/listing_status',
            method: 'post',
        });	
    }

    refuse_reason_list =() => {
        return request({
            url: '/adm/config/listing/listing_refuse_type_list',
            method: 'post',
        });	
    }


    delete_seller =(data) => {
        return request({
            url: '/adm/listing/listing_seller_remove',
            method: 'post',
            data
        });	
    }

    delete_folder =(data) => {
        return request({
            url: '/adm/listing/listing_folder_remove',
            method: 'post',
            data
        });	
    }

    delete_property =(data) => {
        return request({
            url: '/adm/listing/listing_property_remove',
            method: 'post',
            data
        });	
    }

    delete_file =(data) => {
        return request({
            url: '/adm/listing/listing_file_remove',
            method: 'post',
            data
        });	
    }



    add_seller = (data) => {
        return request({
            url: '/adm/listing/listing_add_new_seller',
            method: 'post',
            data
        });	
    }


    add_property= (data) => {
        return request({
            url: '/adm/listing/listing_add_new_property',
            method: 'post',
            data
        });	
    }

    add_file =(data) =>{
        return request({
            url: '/adm/listing/listing_add_new_file',
            method: 'post',
            data
        });	   
    }



    listing_start_process = (data) => {
        return request({
            url: '/adm/listing/listing_status_start_process',
            method: 'post',
            data
        });	
    }

    listing_finish_process = (data) => {
        return request({
            url: '/adm/listing/listing_status_finish_process',
            method: 'post',
            data
        });	
    }


    update_listing_status_action = (data) => {
        return request({
            url: '/adm/listing/update_listing_action',
            method: 'post',
            data
        });	
    }

    update_listing_basic_info = (data) => {
        return request({
            url: '/adm/listing/listing_update_basic_info',
            method: 'post',
            data
        });	
    }

    reject_listing = (data) => {
        return request({
            url: '/adm/listing/listing_reject',
            method: 'post',
            data
        });	
    }

    sign_listing = (data) => {
        return request({
            url: '/adm/listing/listing_signed_doc',
            method: 'post',
            data
        });	
    }

}




let _api = null

const getListingApi = () => {
    if (!_api) {
        _api = new ListingApi();
    }
    return _api;
}

export { getListingApi };


